import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './ourclasses.css';

const OurClasses = () => (
  <div className="gpt3__blog section__padding" id="ourclasses">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">Improve your fitness <br /> with our classes</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={blog01} date="Sep 26, 2021" title="Weight Lifting" fit1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet eros sed velit ullamcorper rhoncus. Sed efficitur non leo eu iaculis. Pellentesque rhoncus pulvinar dui in interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet eros sed velit ullamcorper rhoncus. Sed efficitur non leo eu iaculis.."/>
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog02} date="Sep 26, 2021" title="Power Yoga" fit1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet eros sed velit. "/>
        <Article imgUrl={blog03} date="Sep 26, 2021" title="Cardio" fit1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet eros sed velit. "/>
        <Article imgUrl={blog04} date="Sep 26, 2021" title="Boxing" fit1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet eros sed velit. " />
        <Article imgUrl={blog05} date="Sep 26, 2021" title="Aerobics" fit1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet eros sed velit. " />
      </div>
    </div>
  </div>
);

export default OurClasses;
