import React from 'react';
import OurPlan from '../../components/ourplan/OurPlan';
import './aboutus.css';

const AboutUs = () => (
  <div className="gpt3__whatgpt3 section__margin" id="abtus">
    <div className="gpt3__whatgpt3-feature">
      <OurPlan title="About Us" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent suscipit sollicitudin erat. Proin a purus facilisis, commodo massa ut, ullamcorper tortor. Praesent consectetur pellentesque lorem, in pellentesque dui placerat quis." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">We'll Make You Grow With Our Program</h1>
      <p>Explore the Library</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <OurPlan title="Professional  Trainers" text="Fusce ullamcorper tempor nisi, non fermentum purus tempor non.  Donec semper tristique imperdiet." />
      <OurPlan title="Good Management" text="Nulla facilisi. Curabitur volutpat massa non tortor ullamcorper, quis congue quam mattis. " />
      <OurPlan title="Progress Report" text="Donec euismod venenatis ligula, vel placerat ipsum porta a. Cras ac nulla ligula. Quisque interdum, urna , lectus tellus iaculis sem" />
    </div>
  </div>
);

export default AboutUs;
