import React from 'react';
import { rogers, wallmart, yellowpages, lululemon, apple } from './imports';
import './brand.css';

const Brand = () => (
  <div className="gpt3__brand section__padding">
    <div>
      <img src={rogers} alt="rogers" />
    </div>
    <div>
      <img src={wallmart} alt="wallmart" />
    </div>
    <div>
      <img src={yellowpages} alt="yellowpages" />
    </div>
    <div>
      <img src={lululemon} alt="lululemon" />
    </div>
    <div>
      <img src={apple} alt="apple" />
    </div>
  </div>
);

export default Brand;
