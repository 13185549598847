import React from 'react';
import './ourplans.css';
import OurPlan from "../../components/ourplan/OurPlan";

const featuresData = [
  {
    title: 'Essential',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ut quam tellus. Nullam faucibus dignissim placerat.',
  },
  {
    title: 'Premium',
    text: 'Mauris a urna at velit consectetur consectetur. Cras facilisis est ac consequat aliquam. Donec ut efficitur urna. Maecenas congue massa.',
  },
  {
    title: 'Elite',
    text: 'Curabitur tincidunt ullamcorper tortor, eu auctor purus tincidunt quis. Aenean nec mauris quis orci tristique rutrum sit amet at urna. ',
  },
  {
    title: 'Ultimate',
    text: 'Donec in faucibus elit. Proin ligula justo, tincidunt vitae facilisis sed, imperdiet sit amet felis. Quisque eget ex efficitur, gravida justo in, fermentum ante.',
  },
];

const OurPlans = () => {
  return (
    <div className="gpt3__features section__padding" id="plans">
      <div className="gpt3__features-heading">
        <h1 className="gradient__text">
       Pricing Plans.
        </h1>
        <p>Request Early Access to Get Started</p>
      </div>
      <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <OurPlan title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>

    </div>
  )
}

export default OurPlans;