import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import './header.css';


const Header = () => {
  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">KEEP YOUR MIND 
AND BODY STRONG
WITH US</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent suscipit sollicitudin erat. Proin a purus facilisis, commodo massa ut, ullamcorper tortor. Praesent consectetur pellentesque lorem, in pellentesque dui placerat quis.</p>

        <div className="gpt3__header-content__input">
          <input type="email" placeholder="Your Email Address" />
          <button type="button">Get Started</button>
        </div>

        <div className="gpt3__header-content__people">
        <img src={people} alt="people" />
        <p>1,600 people requested access a visit in last 24 hours</p>
        </div>     
      </div>
        <div className="gpt3__header-image">
          <img src={ai} />
        </div>
    </div>
  )
}

export default Header